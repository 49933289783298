import React, {useContext, useEffect, useState} from 'react';
import { components, GroupBase, OptionProps} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {executeRedactionSearch} from "../documentSearch";
import WebViewerContext from "../../../contexts/webviewer-context";
import FilterFunctionContext from "../../../contexts/filter-function-context";
import SearchStatus from "./SearchStatus";
import {redactionTypeMap} from "../../../constants";
import SearchAcrossDocsContext from "../../../contexts/SearchAcrossDocsContext";

const buildSearchTerms = (searchTerms: any[]) => {
    const options: any = {
        textSearch: [],
        patternSearch: []
    };

    if (!searchTerms) {
        return options;
    }

    searchTerms.forEach((searchTerm: any) => {
        const { type } = searchTerm;
        if (type === redactionTypeMap['TEXT']) {
            options.textSearch.push(searchTerm.label);
        } else {
            options.patternSearch.push(searchTerm)
        }
    });
    return options;
};

const buildSearchSettings = (pageRange: (number | undefined)[] | undefined, wholeWord: boolean) => {
    const settings: { startPage?: number; endPage?: number; wholeWord: boolean } = {
        wholeWord: wholeWord
    };

    if (pageRange) {
        settings.startPage = pageRange[0];
        settings.endPage = pageRange[1];
    }

    return settings;
}


const buildSearchTermsAndSettings = (searchTerms: any[], pageRange: (number|undefined)[]|undefined, wholeWords: boolean) => {
    return [buildSearchTerms(searchTerms), buildSearchSettings(pageRange, wholeWords)]
}

const getStyles = () => ({
    groupHeading: (base: any) => ({
        ...base,
        textTransform: 'none',
        fontSize: '10px',
        color: '#485056',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingTop: '10px',
    }),
    group: (base: any) => ({
        ...base,
        padding: '0px',
    }),
    menu: (base: any) => ({
        ...base,
        padding: '0px 0px 0px 0px',
    }),
    menuList: (base: any) => ({
        ...base,
        padding: '0px',
        backgroundColor: '#FFFFFF',
    }),
    multiValue: (base: any) => ({
        ...base,
        backgroundColor: '#E7EDF3',

        padding: '2px 8px',
        fontSize: '13px',
        borderRadius: '4px',
    }),
    option: (base: any, state: { isFocused: any; }) => ({
        ...base,
        height: '28px',
        display: 'flex',
        fontSize: '13px',
        padding: '6px 8px',
        '&:hover': {
            backgroundColor: '#E7EDF3',
        },
        backgroundColor: state.isFocused ? ('#E7EDF3') : 'transparent',
    }),
    valueContainer: (base: any) => ({
        ...base,
        padding: '1px',
        maxHeight: '70px',
        overflowY: 'scroll',
    }),
    control: (base: any) => ({
        ...base,
        backgroundColor: '#FFFFFF',
        minHeight: '29px',
        borderColor: '#CFD4DA',
        '&:hover': {
            borderColor: '#CFD4DA',
        },
    }),
    placeholder: (base: any) => ({
        ...base,
        fontSize: '13px',
        color: '#ADB5BD',
        paddingLeft: '4px',
    }),
    input: (base: any) => ({
        ...base,
        fontSize: '13px',
        color: '#485056',
        paddingLeft: '3px',
    }),
});

const RedactionOption = (props: {data: any}) => {
    const { data } = props;
    return (
        //@ts-ignore
        <components.Option {...props}>
            {data.label}
        </components.Option>
    );
};

const MultiValueLabel = ({data}: any) => {

    return (
        <div style={{ display: 'flex', height: '18px' }}>
            {data.label}
        </div>
    );
};

const RedactionSearchMultiSelect = (props: {searchTerms: { label: any; value: any; type: string; }[]|undefined,
                                    setSearchTerms: (searchTerms: { label: any; value: any; type: string; }[]) => void
                                    redactionSearchPatterns: { label: string, type: string, regex: RegExp }[],
                                    pageRange: (number|undefined)[]|undefined,
                                    wholeWord: boolean,
                                    setSearchStatus: (s: string) => void, resultsToKeep: any[]}) => {
    const { setInstance, instance } = useContext(WebViewerContext);
    const { searchAcrossDocs, setSearchAcrossDocs } = useContext(SearchAcrossDocsContext);

    const {filterFunction} = useContext(FilterFunctionContext);
    const { searchTerms, setSearchTerms, redactionSearchPatterns, pageRange, wholeWord, setSearchStatus } = props;

    const redactionGroup = [
        {
            label: 'Pattern',
            //Need to add a 'value' field on these objects for them to be valid options in the multiselect
            options: redactionSearchPatterns.map(searchPattern => {return {value: searchPattern.label, label: searchPattern.label, type: searchPattern.type, regex: searchPattern.regex}})
        },
    ];

    const handleChange = (updatedSearchTerms: any) => {
        setSearchTerms(updatedSearchTerms);
        const [compiledSearchTerms, settings] = buildSearchTermsAndSettings(updatedSearchTerms, pageRange, wholeWord);
        executeRedactionSearch(compiledSearchTerms, settings, instance.Core.documentViewer, instance.Core.Search.Mode, filterFunction.function, setSearchStatus, props.resultsToKeep);
    };

    useEffect(() => {
        const doSearch = () => {
            if (searchAcrossDocs && searchTerms) {
                const [compiledSearchTerms, settings] = buildSearchTermsAndSettings(searchTerms!, pageRange, wholeWord);
                executeRedactionSearch(compiledSearchTerms, settings, instance.Core.documentViewer, instance.Core.Search.Mode, filterFunction.function, setSearchStatus, props.resultsToKeep);
            } else {
                setSearchTerms([])
            }
        }
        if (instance) {
            instance.Core.documentViewer.addEventListener('documentLoaded', doSearch)
        }
        return () => {
            if (instance) {
                instance.Core.documentViewer.removeEventListener('documentLoaded', doSearch);
            }
        }
    }, [instance, searchTerms, pageRange, filterFunction, searchAcrossDocs]);

    const handleCreate = (newValue: any) => {
        const textTerm = {
            label: newValue,
            value: newValue,
            type: redactionTypeMap['TEXT']
        };
        // Initially search terms are null so we safeguard against this
        const nonNullSearchTerms = searchTerms || [];
        const updatedSearchTerms = [...nonNullSearchTerms, textTerm];
        setSearchTerms(updatedSearchTerms);
        const [options, settings] = buildSearchTermsAndSettings(updatedSearchTerms, pageRange, wholeWord);
        executeRedactionSearch(options, settings, instance.Core.documentViewer, instance.Core.Search.Mode, filterFunction.function, setSearchStatus, props.resultsToKeep);
    };

    const styles = getStyles();

    return (
        <CreatableSelect
            isMulti
            value={searchTerms}
            options={redactionGroup}
            styles={styles}
            components={{ Option: RedactionOption, MultiValueLabel, IndicatorsContainer: () => null }}
            placeholder='Search Document'
            formatCreateLabel={(value) => `Search ${value}`}
            onChange={handleChange}
            onCreateOption={handleCreate}
        />
    );
};

export default RedactionSearchMultiSelect;
