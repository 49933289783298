/* fileManagement.ts */
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
    commitApiFile,
    postApiFile,
    putFileToS3PreSignedUrl,
    requestUploadUrlForFile, updateFileTagByVersionId,
} from "../services/files"
import {FileState} from "../models/FileState";
import JSZip from "jszip";
import {LargeFileUpload} from "../models/LargeFileUpload";
import {PreSignedS3UploadUrl} from "../models/PreSignedS3UploadUrl";

export function validateFile(fileType : string, fileName: string) {
    return (fileType === "application/pdf" || fileType === "text/csv"  || fileType === "application/vnd.ms-excel"  || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        || fileType === "application/x-sas-data"  || fileType === "application/x-sas-xport" || fileType === "application/arx" ) ||
        (fileType === "" && fileName.endsWith(".deid")) //Files from Risk (.deid) don't get assigned a MIME type in OSX, so we validate them this way.
}

export async function updateFileUsingS3(file: any, projectId: number, fileId: string, temporaryLocalChangesSave: boolean, tag?: string): Promise<FileState | undefined>{
    try {
        if(!validateFile(file.type, file.name)) {
            reportError("error!");
            return;
        }

        const preSignedUrl = await requestUploadUrlForFile() as PreSignedS3UploadUrl
        await putFileToS3PreSignedUrl(preSignedUrl.url, file)

        const fileUpload : LargeFileUpload = {
            preSignedUrl,
            name: decodeURI(file.name),
            category: "",
            projectId: projectId,
            isDocOpen: true,
            size: file.size,
            openedBy: "", //backend overrides this value
            createdByName: "", //backend overrides this value
            isUpdating: true,
            isTemporaryLocalChanges: temporaryLocalChangesSave,
            id: fileId
        };

        await commitApiFile(fileUpload)
        if(tag && !temporaryLocalChangesSave) {
           await updateFileTagByVersionId(projectId.toString(), decodeURI(file.name), "current", tag);
        }

        return undefined
    } catch (exception){
        Sentry.captureException(exception);
        console.log(exception);
        return undefined;
    }
}

export async function uploadFileUsingS3(file: any, projectId: number, fileUploader: number | undefined,
                                        isSavingExistingFile: boolean, initializeDocAsOpen: boolean): Promise<FileState|undefined>{

    try {
        if(!validateFile(file.type, file.name)) {
            reportError("error!");
            return;
        }

        const preSignedUrl = await requestUploadUrlForFile() as PreSignedS3UploadUrl
        await putFileToS3PreSignedUrl(preSignedUrl.url, file)
        const category = file.name.endsWith('deid') ? "RLS Protect Risk" : "Unassigned"
        const fileUpload : LargeFileUpload = {
            preSignedUrl,
            name: file.name,
            category: category,
            projectId: projectId,
            isDocOpen: initializeDocAsOpen,
            size: file.size,
            openedBy: fileUploader?.toString() || "", //backend overrides this value
            createdByName: "", //backend overrides this value
            isUpdating: false,
            id: "-1"
        };
        const response = await commitApiFile(fileUpload);
        return {
            ...response,
            name: file.name
        }
    } catch (exception){
        Sentry.captureException(exception);
        console.log(exception);
        return undefined;
    }
}

//Keep this method here. We get a presigned URL from the backend but we still need to get the file on the front end.
export const getFileNoCache = async (url: string) => {
    const result = await axios.get(
        url,
        {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        }
    )
    return result.data;
}

//The above method does not work for Excel files. This one works for both XLSX and CSV
export const getExcelFileNoCache = async (url: string) => {
    const result = await axios.get(
        url,
        {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Content-Type': 'blob'
            },
            responseType: 'arraybuffer'
        }
    )
    return result.data;
}

export const getFileNoCacheAndUnzipInMemory = async (url: string, signal: AbortSignal): Promise<Map<string, Promise<string>>> => {
    try {
        const response = await axios.get(url, {
            // Query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            responseType: 'arraybuffer', // To handle binary data
            signal: signal
        });
        if (response.status === 200) {
            const zip = new JSZip();
            await zip.loadAsync(response.data);
            let extractedFiles = new Map;
            zip.forEach((relativePath, file) => {
                extractedFiles.set(relativePath, file.async('text'));
            });
            return extractedFiles;
        } else {
            throw new Error('Failed to download file');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
