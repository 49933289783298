import React, {useContext} from 'react';
import RedactionSearchResults from './RedactionSearchResults';
import {CATEGORY_SPLITTER} from "../../../constants";
import SelectedMarkStyleContext from "../../../contexts/selected-mark-style-context";

const getColors = (colorObj, instance) => {
    const {R, G, B, A} = colorObj;
    return new instance.Core.Annotations.Color(R, G, B, A);
}

// Returns either 'cci', 'cbi' or 'normal'
// First check if the label is CCI or CBI. Then check if the name is CCI or CBI. If none of those are true, return 'normal'.
export function getStyleCategory(label) {
    if (label.toLowerCase() === 'cci' || label.toLowerCase() === 'cbi') {
        return label.toLowerCase();
    } else {
        return 'normal'
    }
}

//Returns a two element array with the style category and the display category.
//The "style category" is either cci, cbi, or normal. This determines the style of the redaction (i.e. it's overlay text and color)
//The "display category" is something like age, date, or patient ID. It is the category that is displayed in the UI.
export function getStyleAndDisplayCategory(type) {
    if (!type) {
        return ['normal', 'text']
    }
    if (type.indexOf(CATEGORY_SPLITTER) === -1) {
        return [getStyleCategory(type), type]
    }
    const rawStyleAndDisplayCategory = type.split(CATEGORY_SPLITTER);
    return [getStyleCategory(rawStyleAndDisplayCategory[0]), rawStyleAndDisplayCategory[1]]
}

function createRedactionAnnotations(searchResults, activeToolStyles, mainStyle, instance) {
    const { StrokeColor, OverlayText, FillColor, TextColor } = activeToolStyles;
    return searchResults.map((result) => {
        const [styleCategory, displayCategory] = getStyleAndDisplayCategory(result.type)
        const redaction = new instance.Core.Annotations.RedactionAnnotation({
            PageNumber: result.page_num,
            Quads: result.quads.map((quad) => quad.getPoints())
        });
        redaction.PageNumber = result.page_num;
        redaction.Quads = result.quads.map((quad) => quad.getPoints());
        redaction.StrokeColor = usingPresets(styleCategory, mainStyle) ? getColors(mainStyle.props[styleCategory]['StrokeColor'], instance) : StrokeColor;
        redaction.OverlayText = usingPresets(styleCategory, mainStyle) ? mainStyle.props[styleCategory]['OverlayText'] : OverlayText;
        redaction.FillColor = usingPresets(styleCategory, mainStyle) ? getColors(mainStyle.props[styleCategory]['FillColor'], instance) : FillColor;
        redaction.FontSize = usingPresets(styleCategory, mainStyle) ? mainStyle.props.FontSize : '9pt';
        redaction.TextColor = usingPresets(styleCategory, mainStyle) ? getColors(mainStyle.props[styleCategory]['TextColor'], instance) : TextColor;
        redaction.setContents(result.result_str);
        redaction.setCustomData('trn-annot-preview', result.result_str);
        redaction.type = displayCategory;
        //In Adobe and Docs Desktop, the Author field is displayed as the type. So we're staying consistent with that and
        //we added a custom field that actually contains the author.
        redaction.Author = displayCategory;
        redaction.setCustomData('author', instance.Core.annotationManager.getCurrentUser());
        redaction.setCustomData('trn-redaction-type', displayCategory);

        return redaction;
    });
}

//We expect this to return true unless they're using the Custom mark style. In that case it should use the
//settings the user selected in the tool bar (the active tool styles)
function usingPresets(category, mainStyle) {
    return category && mainStyle?.props && mainStyle.props[category]
}

function RedactionSearchResultsContainer(props) {
    const { onCancelSearch, deleteSelectedResults } = props;
    const { selectedMarkStyle, setSelectedMarkStyle } = useContext(SelectedMarkStyleContext)

    const markSelectedResultsForRedaction = (searchResults, instance) => {
        const defaultRedactionStyles = { OverlayText: '', StrokeColor: new instance.Core.Annotations.Color(255, 0, 0) };
        const redactionAnnotations = createRedactionAnnotations(searchResults, defaultRedactionStyles, selectedMarkStyle, instance);
        instance.Core.annotationManager.addAnnotations(redactionAnnotations);
    };

    return (
        <RedactionSearchResults
            markSelectedResultsForRedaction={markSelectedResultsForRedaction}
    deleteSelectedResults={deleteSelectedResults}
    {...props}
    />);
}

export default RedactionSearchResultsContainer;